// pre IE
require("babel-polyfill");

const angular = require('angular');
let mapInited = false;

initMap();

angular.element(document).ready(function () {
	angular.bootstrap(document.getElementById('realEstateFilter'), [require('./realEstate/filter')]);
});

$(window).resize(function () {
	if (!mapInited) {
		initMap();
	}
});

function initMap() {
	//if ($(window).width() > 767) {
		angular.element(document).ready(function () {
			angular.bootstrap(document.getElementById('realEstateMap'), [require('./realEstate/map')]);
		});

		mapInited = true;
	//}
}