const angular = require('angular');
import { MarkerClusterer } from "@googlemaps/markerclusterer";

//const MarkerClusterer = require('@googlemaps/markerclusterer');
var markerCluster;
    
module.exports = /*@ngInject*/ function (
    $location,
    $q,
    $sce,
    $scope,
    $timeout,
    $window,
    FilterProvider,
    MapProvider,
    RealEstateSpinner,
) {
    // pre spinner
    let projectsUrl;
    let timer;
    let initialFilter = {};
    let map = {};
    let overlay; // vyznacena plocha na mape
    let drawingManager;
    let bounds = null;
	$scope.firstTimeFiltered = true;
	
    const infowindows = [];
    const mapStyle = [];
    const mapCenter = {
        lat: 48.6750486,
        lng: 20.0580432
    };
    const icons = [
        '/images/pins/pin_1.png',
        '/images/pins/pin_2.png',
        '/images/pins/pin_3.png',
        '/images/pins/pin_4.png',
        '/images/pins/pin_5.png',
        '/images/pins/pin_6.png',
        '/images/pins/pin_7.png',
        '/images/pins/pin_8.png',
        '/images/pins/pin_9.png',
        '/images/pins/pin_10.png',
        '/images/pins/pin_11.png',
        '/images/pins/pin_12.png'
    ];

    $scope.activeCat = '';
    $scope.advertTypes = {
        Byty: {
            name: '',
            key: '',
            amount: 0,
            icon: '/images/pins/pin_B.png'
        },
        Domy: {
            name: '',
            key: '',
            amount: 0,
            icon: '/images/pins/pin_D.png'
        },
        Pozemky: {
            name: '',
            key: '',
            amount: 0,
            icon: '/images/pins/pin_P.png'
        },
        Objekty: {
            name: '',
            key: '',
            amount: 0,
            icon: '/images/pins/pin_O.png'
        },
        'Developerské projekty': {
            name: '',
            key: '',
            amount: 0,
            icon: '/images/pins/pin_DP.png'
        },
        Ostatné: {
            name: '',
            key: '',
            amount: 0,
            icon: '/images/pins/pin_10.png'
        },
    };
    $scope.amountFromMap = 0;
    $scope.categories = [];
    $scope.centerMap = true;
    $scope.cities = [];
    $scope.countries = [];
    $scope.filter = {
        balcon: 0,
        category: [],
        city: [],
        coordinates: [],
        coordinateType: '',
        geoJSON: {
            box: [],
            polygon: [],
            type: ''
        },
        country: '',
        limit: 48,
        new: 0,
        page: 0,
        priceFrom: null,
        priceTo: null,
        region: [],
        sizeFrom: null,
        sizeTo: null,
        skip: 0,
        sort: 'new',
        specie: '',
        state: '',
        tags: [],
        type: '',
        virtual: 0,
    };
    $scope.isReady = false;
    $scope.location = [];
    $scope.locations = [];
    $scope.markerCluster = null;
    $scope.markers = [];
    $scope.regions = [];
    $scope.selectedType = '';
    $scope.setChaty = false;
    $scope.sort = 'new';
    $scope.species = [];
    $scope.spinner = RealEstateSpinner.proccess;
    $scope.states = [];
    $scope.types = [];

    $scope.filterProjects = filterProjects;
    $scope.getMarkersFromPaginator = getMarkersFromPaginator;
    $scope.isActive = isActive;
    $scope.loadMarkers = loadMarkers;
    $scope.nextMarkersFromPaginator = nextMarkersFromPaginator;
    $scope.polygonComplete = polygonComplete;
    $scope.prevMarkersFromPaginator = prevMarkersFromPaginator;
    $scope.range = range;
    $scope.rectangleComplete = rectangleComplete;
    $scope.setSpecieFromFront = setSpecieFromFront;
    $scope.showAllMarkers = showAllMarkers;
    $scope.toggleCategory = toggleCategory;
    $scope.toggleCity = toggleCity;
    $scope.toggleRegion = toggleRegion;

    const initMap = function () {
        map = new google.maps.Map(document.getElementById('filterMap'), {
            center: mapCenter,
            disableDefaultUI: true,
            zoomControl: true,
            styles: mapStyle,
            zoom: 7
        });
		
		
		
		
        google.maps.event.addListenerOnce(map, 'idle', function () {
            loadMarkers();
        });
        google.maps.event.addListener(map, 'dragend', function (evt) {
            if ($scope.isReady === true) {
                $scope.filter.page = 0;
                $scope.filter.skip = 0;
                rectangleComplete(map);
                loadMarkers($scope.filter.coordinateType, $scope.filter.coordinates);
            }
        });
        google.maps.event.addListener(map, 'zoom_changed', function (evt) {
            if ($scope.isReady === true) {
                $scope.filter.page = 0;
                $scope.filter.skip = 0;
                rectangleComplete(map);
                loadMarkers($scope.filter.coordinateType, $scope.filter.coordinates);
            }
        });
        $(window).resize(function () {
            google.maps.event.trigger(map, "resize");
        });
		
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
			(position) => {
			  const pos = {
				lat: position.coords.latitude,
				lng: position.coords.longitude,
			  };
			  map.setCenter(pos);
			  map.setZoom(12);
			},
			() => {
			  //handleLocationError(true, infoWindow, map.getCenter());
			});
		}
		
    };

    init();

    $scope.$watch('location', function (location, oldVal) {
        if (location !== oldVal && location.length > 0) {
            const isCountry = $scope.countries.some(function (country) {
                return country.id === location[0].id;
            });
            if (isCountry) {
                loadRegions(location[0].id);
                $scope.filter.country = location[0].id;
                $scope.filter.state = '';
            } else {
                $scope.filter.state = location[0].id;
                $scope.filter.country = '';
            }
            $scope.cities = [];
            $scope.regions = [];
            $scope.filter.region = [];
            $scope.filter.city = [];
            loadMarkers();
        }
    });
var timeout;

    $scope.$watch('filter.fullText', function (newVal, oldVal) {
        if (newVal !== oldVal) {
			console.log('hladam');
            $scope.filter.page = 0;
            $scope.filter.skip = 0;
		/*	$scope.filter.coordinates = [];
			$scope.filter.coordinateType = '';
			$scope.filter.geoJSON = [];*/
			
				
		//		loadMarkers();
		
			
			if(timeout) {
              clearTimeout(timeout);
              timeout = null;
          }
          timeout = setTimeout(loadMarkers, 1000);
			
		}
    });

    $scope.$watch('filter.priceFrom', function (newVal, oldVal) {
        if (newVal !== oldVal) {
            $scope.filter.page = 0;
            $scope.filter.skip = 0;
            loadMarkers();
        }
    }, true);

    $scope.$watch('filter.priceTo', function (newVal, oldVal) {
        if (newVal !== oldVal) {
            $scope.filter.page = 0;
            $scope.filter.skip = 0;
            loadMarkers();
        }
    }, true);

    $scope.$watch('filter.sizeFrom', function (newVal, oldVal) {
        if (newVal !== oldVal) {
            $scope.filter.page = 0;
            $scope.filter.skip = 0;
            loadMarkers();
        }
    }, true);

    $scope.$watch('filter.sizeTo', function (newVal, oldVal) {
        if (newVal !== oldVal) {
            $scope.filter.page = 0;
            $scope.filter.skip = 0;
            loadMarkers();
        }
    }, true);

    $scope.$watch('filter.type', function (regionId, oldVal) {
        if (regionId !== oldVal) {
            $scope.filter.page = 0;
            $scope.filter.skip = 0;
            loadMarkers();
        }
    });

    $scope.$watch('filter.region', function (regionId, oldVal) {
        if (regionId !== oldVal) {
            loadCities(regionId);
        }
    });

    $scope.$watch('filter.sort', function (newVal, oldVal) {
        if (newVal !== oldVal) {
            $scope.filter.page = 0;
            $scope.filter.skip = 0;
            loadMarkers();
        }
    });

    $scope.$watch('filter.specie', function (specieId, oldVal) {
        if (specieId !== oldVal) {
            loadCategories(specieId);
            $scope.filter.page = 0;
            $scope.filter.skip = 0;
            $scope.filter.category = [];
            if ($scope.activeCat !== 'Chaty') {
                $scope.activeCat = specieId;
            }
            if ($scope.setChaty) {
                $scope.filter.category = ['Chata'];
            }
            loadMarkers();
        }
    });

    function init() {
        const speciePromise = FilterProvider.loadSpecies()
            .then(function (species) {
                $scope.species = species;

                // nastavit nazvy pre typy nehnutelnost (kvoli mutacii bude iny nazov preto nemozno hardcode)
                species.forEach(function (specie) {
                    $scope.advertTypes[specie.label].name = specie.value;
                    $scope.advertTypes[specie.label].key = specie.label;
                });
            });

        const typePromise = FilterProvider.loadTypes()
            .then(function (types) {
                $scope.types = types;
            });

        RealEstateSpinner.addPromise(speciePromise);
        RealEstateSpinner.addPromise(typePromise);

        loadStates();

        // listener pre "enter press"
        $('#mapFilterSection').find('input').keypress(function (e) {
			delay(function(){
				if ((event.keyCode || event.which) == 13) {
					console.log('hladam');
					loadMarkers();
				}
			},400);
        });

        // init map podla parametrov z URL
        const data = {
            specie: $location.search().specie ? $location.search().specie : '',
            type: $location.search().type ? $location.search().type : '',
            state: $location.search().state ? $location.search().state : '',
            country: $location.search().country ? $location.search().country : '',
            region  : $location.search().region ? $location.search().region : [],
            city    : $location.search().city ? $location.search().city : [],
            priceFrom: $location.search().priceFrom ? $location.search().priceFrom : '',
            priceTo: $location.search().priceTo ? $location.search().priceTo : '',
            sizeFrom: $location.search().sizeFrom ? $location.search().sizeFrom : '',
            sizeTo: $location.search().sizeTo ? $location.search().sizeTo : '',
            balcon: $location.search().balcon ? true : 0,
            virtual: $location.search().virtual ? true : 0,
			fullText: $location.search().fullText ? $location.search().fullText : '',
            new: $location.search().new ? true : 0
        };

        if (data.priceTo) {
            data.priceTo = parseInt(data.priceTo);
        }

        if (data.priceFrom) {
            data.priceFrom = parseInt(data.priceFrom);
        }

        if (data.sizeFrom) {
            data.sizeFrom = parseInt(data.sizeFrom);
        }

        if (data.sizeTo) {
            data.sizeTo = parseInt(data.sizeTo);
        }
		
        $scope.filter = angular.extend($scope.filter, data);
        if (data.specie) {
            FilterProvider.loadCategories(data.specie)
                .then(function (categories) {
                    categories.forEach(function (category) {
                        $scope.categories.push({
                            name: category.label,
                            key: category.value,
                            amount: 0,
                            icon: '',
                            selected: false
                        });
                    });

                    let inputCategories = angular.isDefined($location.search()['category[]']) ? $location.search()['category[]'] : [];

                    if (inputCategories && typeof inputCategories === 'string') {
                        inputCategories = [inputCategories];
                    }

                    if (Array.isArray(inputCategories) && inputCategories.length) {
                        $scope.categories.forEach(function (category) {
                            if (inputCategories.indexOf(category.key) !== -1) {
                                category.selected = true;
                            }
                        });

                        toggleCategory();
                    }

                    addMapInitiator(initMap);
                });
        } else {
            addMapInitiator(initMap);
        }

        $(document).ready(function () {
            // listener pre "enter press"
            $('#paramFilterSection').find('input').keypress(function (e) {
                if ((event.keyCode || event.which) == 13) {
                    filterProjects();
                }
            });
        });
    }

    function addMapInitiator(initFnc) {
        if (!window._googleMapBoostrap) {
            window._googleMapBoostrap = [];
        }
        window._googleMapBoostrap.push(initFnc);
        if (!window.boostrapGoogleMaps) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=visualization,drawing,geometry&key=' + window.mapApiKey + '&callback=boostrapGoogleMaps&region=sk&language=sk';
            document.body.appendChild(script);
            window.boostrapGoogleMaps = function () {
                if (window._googleMapBoostrap) {
                    window._googleMapBoostrap.forEach(function (boostrapMap) {
                        boostrapMap();
                    });
                }
            }
        }
    }

    function rectangleComplete(e) {
        const NE = e.getBounds().getNorthEast();
        const SW = e.getBounds().getSouthWest();
        $scope.filter.coordinates = [
            [NE.lat(), NE.lng()],
            [SW.lat(), SW.lng()]
        ];
        // https://docs.mongodb.com/manual/reference/operator/query/box/
        $scope.filter.geoJSON.box = [
            // bottom left
            [SW.lng(), SW.lat()],
            // upper right
            [NE.lng(), NE.lat()]
        ];
        $scope.filter.geoJSON.type = 'Rectangle';
        $scope.filter.coordinateType = 'Rectangle';
    }

    function polygonComplete(e) {
        const coordinates = e.getPath().getArray();
        $scope.filter.coordinates = coordinates.map(function (coor) {
            return [coor.lat(), coor.lng()];
        });
        // https://docs.mongodb.com/manual/reference/operator/query/polygon/
        $scope.filter.geoJSON.polygon = coordinates.map(function (point) {
            return [point.lng(), point.lat()];
        });
        $scope.filter.geoJSON.type = 'Polygon';
        $scope.filter.coordinateType = 'Polygon';
    }

    function addMarkers(markersData) {
        const categories = [];
        $scope.markers = [];
        bounds = new google.maps.LatLngBounds();
        markersData.forEach(function (markerData) {
            const position = {
                lat: markerData.position.latitude,
                lng: markerData.position.longitude
            };
            const icon = {
                url: '/images/marker.png',
				scaledSize: new google.maps.Size(50, 50), // scaled size
				origin: new google.maps.Point(0,0), // origin
				anchor: new google.maps.Point(0, 0) // anchor
               // scaledSize: new google.maps.Size(50, 75), // scaled size
               /* origin: new google.maps.Point(0, 0), // origin
                anchor: new google.maps.Point(0, 0), // anchor
                labelOrigin: new google.maps.Point(30, 10) // anchor*/
            };
            let marker = new google.maps.Marker({
                position: position,
                map: map,
                title: markerData.name,
                icon: icon,
               /* label: {
                    text: markerData.price.replace(/<[^>]*>?/gm, ''),
                    color: "#000",
                    fontSize: "10px",
                    fontWeight: "normal"
                },*/
                customInfo: {
                    specie: markerData.specie.label,
                }
            });
            const infoWindow = new google.maps.InfoWindow({
                content: [
                    '<div class="marker-info-window">',
                    '<div style="float:left;">',
                    '<a href="' + markerData.url + '" target="_blank">',
                    '<img class="img-responsive" src="' + markerData.image + '">',
                    '</a>',
                    '</div>',
                    '<div style="float:left;padding-left:10px;max-width:400px;">',
                    '<h5>',
                    '<a href="' + markerData.url + '" target="_blank">',
                    '' + markerData.name,
                    '</a>',
                    '</h5>',
                    '<p>' + markerData.locationName + '</p><br>',
					'<div style="float:left;">',
                    '<strong >' + markerData.price + '</strong>',
                    '</div>',
					'<div style="float:right;">',
					'<a href="' + markerData.url + '" style="border:1px solid #329d4b;color:#fff;background:#329d4b;border-radius:3px;padding:3px" target="_blank">',
					'<i class="fa fa-external-link" aria-hidden="true"></i> Otvoriť ponuku',
					'</a>',
					'</div>',
                    '</div>',
                    '</div>'
                ].join('')
            });
            infowindows.push(infoWindow);
            marker.addListener('click', function () {
                for (let i = 0; i < infowindows.length; i++) {
                    infowindows[i].close();
                }
                infoWindow.open(map, marker);
            });
            bounds.extend(marker.getPosition());
            $scope.markers.push(marker);

        });
        if (markersData.length > 1 && $scope.centerMap) { map.fitBounds(bounds); }
        if (markersData.length == 1 && $scope.centerMap) {
            map.fitBounds(bounds);
            map.setZoom(12);
        }

        const options = {
            minimumClusterSize: 5,
            gridSize: 1,
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
        };
		const markersTmp = $scope.markers;
        markerCluster = new MarkerClusterer({ map:map, markers:markersTmp,renderer: {
      render: ({markers, _position: position}) => {
		  const color = markers.length > 10 ? "#ff0000" : "#0000ff";
        // create svg url with fill color
        const svg = window.btoa(`
  <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity=".6" r="70" />
    <circle cx="120" cy="120" opacity=".3" r="90" />
    <circle cx="120" cy="120" opacity=".2" r="110" />
  </svg>`);
        return new google.maps.Marker({
          position: {
            lat: position.lat(),
            lng: position.lng(),
          },
          icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new google.maps.Size(45, 45),
            },
          label: {
                text: String(markers.length),
                color: "rgba(255,255,255,0.9)",
                fontSize: "12px",
            },
          color: '#ffffff',
		  title: 'Počet nehnuteľnosti'
        });
      },
    }, });
    }

    function loadStates() {
        const promises = [];
        FilterProvider.loadStates()
            .then(function (states) {
                $scope.states = states;
                $scope.states.forEach(function (state) {
                    const promise = loadCountries(state.id).then(function (countries) {
                        state.countries = countries.map(function (country) {
                            country.placeholder = "<span class='country'>" + country.name + "</span>";
                            country.checked = $scope.filter.country === country.id;
                            return country;
                        });
                    });
                    promises.push(promise);
                });
                $q.all(promises).then(function () {
                    $scope.states.forEach(function (state) {
                        state.placeholder = "<span class='state'>" + state.name + "</span>";
                        state.checked = $scope.filter.state === state.id;
                        $scope.locations.push(state);
                        $scope.locations = $scope.locations.concat(state.countries);
                    });
                });
            });
    }

    function loadCountries(stateId) {
        const promise = FilterProvider.loadCountries(stateId)
            .then(function (countries) {
                $scope.countries = $scope.countries.concat(countries);
                return countries;
            });
        RealEstateSpinner.addPromise(promise);
        return promise;
    }

    function loadRegions(countryId) {
        const promise = FilterProvider.loadRegions(countryId)
            .then(function (regions) {
                $scope.regions = regions;
                const inputRegions = angular.isDefined($location.search()['region[]']) ? $location.search()['region[]'] : [];
                if (inputRegions.length) {
                    $scope.regions.forEach(function (region) {
                        if (inputRegions.indexOf(region.id) !== -1) {
                            $scope.filter.region.push(region.id);
                        }
                    });
                }
            });
        RealEstateSpinner.addPromise(promise);
        return promise;
    }

    function loadCities(regionId) {
        const promise = FilterProvider.loadCities(regionId)
            .then(function (cities) {
                $scope.cities = cities;
                var cities = angular.isDefined($location.search()['city[]']) ? $location.search()['city[]'] : [];
                if (cities.length) {
                    $scope.cities.forEach(function (city) {
                        if (cities.indexOf(city.id) !== -1) { $scope.filter.city.push(city.id); }
                    });
                }
            });
        RealEstateSpinner.addPromise(promise);
        return promise;
    }

    function loadCategories(specie) {
        const promise = FilterProvider.loadCategories(specie)
            .then(function (categories) {
                $scope.categories = categories;
            });
        RealEstateSpinner.addPromise(promise);
        return promise;
    }

    function setSpecieFromFront(specie) {
        $scope.setChaty = false;
        $scope.activeCat = specie;
        if (specie == "Chaty") {
            $scope.filter.specie = "Domy";
            $scope.setChaty = true;
        } else {
            $scope.filter.specie = specie;
        }
    }

    function clearMarkers() {
        for (let i = 0; i < $scope.markers.length; i++) {
            $scope.markers[i].setMap(null);
        }
        if (markerCluster) {
            markerCluster.clearMarkers();
        }
        $scope.markers = [];

    }

    function clearAdvertTypes() {
        $scope.selectedType = '';
        angular.forEach($scope.advertTypes, function (type) {
            type.amount = 0;
        });
    }

    function showAllMarkers() {
        $scope.selectedType = '';
        $scope.filter.specie = '';
        for (let i = 0; i < $scope.markers.length; i++) { $scope.markers[i].setVisible(true); }
        map.fitBounds(bounds);
    }

    function loadMarkers(type, coordinates) {
        $scope.isReady = false;
        if ($scope.sort == $scope.filter.sort) {
            $scope.centerMap = true;
            if (overlay) { overlay.setMap(null); }
            if ($scope.filter.specie) { clearAdvertTypes(); }
            clearMarkers();
            clearAdvertTypes();
        }
        if (angular.isDefined(type)) {
            $scope.filter.coordinateType = type;
            $scope.centerMap = false;
        }
        if (angular.isDefined(coordinates)) {
            $scope.filter.coordinates = coordinates;
            $scope.centerMap = false;
        }
		if(angular.isDefined($location.search().adverts)){
			$scope.firstTimeFiltered = false;
		}
		if($scope.firstTimeFiltered){
		
		
				
				setTimeout(function () {
				let localFilter = angular.copy($scope.filter);
				localFilter.limit=5000;
				const promise = MapProvider.loadMarkers(localFilter)
					.then(function (result) {
						let markerForMap = [];
						let markerForResult = [];
						$scope.amountFromMap = result.totalCount;
						$scope.totalPages = 0;
						result.markers.forEach(function (marker) {
							if (angular.isDefined(marker.position)) {
								if (angular.isDefined(marker.position.latitude) && angular.isNumber(marker.position.latitude)) {
									if (angular.isDefined(marker.position.longitude) && angular.isNumber(marker.position.longitude)) {
										markerForMap.push(marker);
									}
								}
							}
							
							if(marker.top===true){
								if (marker.image === "") {
									marker.image = '/images/img-120x120-2b.jpg';
									marker.imagebig = '/images/img-720x360-2b.jpg';
								} else {
									marker.imagebig = marker.image.replace("120", "720");
									marker.imagebig = marker.imagebig.replace("120", "360");
								}
								marker.priceHtml = $sce.trustAsHtml(marker.price);
								marker.totalArea = $sce.trustAsHtml(marker.totalArea);
								marker.usableArea = $sce.trustAsHtml(marker.usableArea);
								marker.floor = $sce.trustAsHtml(marker.floor);
								marker.rooms = $sce.trustAsHtml(marker.rooms);
								marker.hidden_price_text = $sce.trustAsHtml(marker.hidden_price_text);
								marker.mainCat = marker.specie.name;
								markerForResult.push(marker);
							}
							
						});
						addMarkers(markerForMap);
						$scope.realestates = markerForResult;
					}).finally(function () {
						$scope.sort = angular.copy($scope.filter.sort);
						$scope.isReady = true;
					});
				RealEstateSpinner.addPromise(promise);
				$scope.firstTimeFiltered = false;
				return promise;
				}, 1000);
			clearTimeout(timeout);
				
			
			
		}
		else{
			setTimeout(function () {
			
				const promise = MapProvider.loadMarkers($scope.filter)
					.then(function (result) {
						let markerForMap = [];
						let markerForResult = [];
						$scope.amountFromMap = result.totalCount;
						$scope.totalPages = Math.ceil(parseFloat($scope.amountFromMap / $scope.filter.limit));
						result.markers.forEach(function (marker) {
							if (angular.isDefined(marker.position)) {
								if (angular.isDefined(marker.position.latitude) && angular.isNumber(marker.position.latitude)) {
									if (angular.isDefined(marker.position.longitude) && angular.isNumber(marker.position.longitude)) {
										markerForMap.push(marker);
									}
								}
							}
							if (marker.image === "") {
								marker.image = '/images/img-120x120-2b.jpg';
								marker.imagebig = '/images/img-720x360-2b.jpg';
							} else {
								marker.imagebig = marker.image.replace("120", "720");
								marker.imagebig = marker.imagebig.replace("120", "360");
							}
							marker.priceHtml = $sce.trustAsHtml(marker.price);
							marker.totalArea = $sce.trustAsHtml(marker.totalArea);
							marker.usableArea = $sce.trustAsHtml(marker.usableArea);
							marker.floor = $sce.trustAsHtml(marker.floor);
							marker.rooms = $sce.trustAsHtml(marker.rooms);
							marker.hidden_price_text = $sce.trustAsHtml(marker.hidden_price_text);
							marker.mainCat = marker.specie.name;
							markerForResult.push(marker);
							
						});
						addMarkers(markerForMap);
						$scope.realestates = markerForResult;
					}).finally(function () {
						$scope.sort = angular.copy($scope.filter.sort);
						$scope.isReady = true;
					});
				RealEstateSpinner.addPromise(promise);
				return promise;
				}, 1000);
			clearTimeout(timeout);
		}
        
    }

    function toggleCategory() {
        const selectedCategories = [];
        $scope.categories.forEach(function (category) {
            if (category.checked) { selectedCategories.push(category.value); }
        });
        $scope.filter.category = selectedCategories;
        loadMarkers();
    }

    function toggleRegion() {
        const selectedRegions = [];
        $scope.regions.forEach(function (region) {
            if (region.checked) { selectedRegions.push(region.id); }
        });

        $scope.filter.region = selectedRegions;
        $scope.filter.page = 0;
        $scope.filter.skip = 0;
        $scope.filter.city = [];
        loadMarkers();
    }

    function toggleCity() {
        const selectedRegions = [];
        $scope.cities.forEach(function (city) {
            if (city.checked) { selectedRegions.push(city.id); }
        });
        $scope.filter.city = selectedRegions;
        $scope.filter.page = 0;
        $scope.filter.skip = 0;
        loadMarkers();
    }

    function filterProjects(showMap) {
        const fitlerData = {};
        const displayMap = showMap || false;
        angular.forEach($scope.filter, function (param, key) {
            if (key !== 'limit' && key !== 'skip' && key !== 'page') {
                if (param) {
                    fitlerData[key] = param;
                    if (key === 'sort' && param === 'new') {
                        fitlerData['sort'] = 'times.created';
                    }
                    if (key === 'sort' && param === 'old') {
                        fitlerData['sort'] = 'times.created';
                        fitlerData['order'] = 'asc';
                    }
                    if (key === 'sort' && param === 'cheaper') {
                        fitlerData['sort'] = 'Základné údaje.Cena';
                        fitlerData['order'] = 'asc';
                    }
                    if (key === 'sort' && param === 'expensive') {
                        fitlerData['sort'] = 'Základné údaje.Cena';
                        fitlerData['order'] = 'desc';
                    }
                }
            }
        });

        const queryString = $.param(fitlerData);
        const hash = displayMap ? '#map-filter' : '#result-filter';

        let href = 'nehnutelnosti' + '?' + queryString;
        href = href + (queryString ? '&adverts' : 'adverts');

        if (angular.equals(initialFilter, $scope.filter)) {
            if (hash == '#map-filter') {
                $('#paramFilterSection').hide();
                $('#mapFilterSection').show();
                $('.adverts-list').hide();
                $('.tabNav').find('li').removeClass('active').eq(1).addClass('active');
            } else {
                $('.adverts-list').show();
            }
        }
        $window.location.href = href + hash;
    }


    function resizeMap() {
        setTimeout(function () {
            google.maps.event.trigger(map, 'resize');
            map.setCenter(mapCenter);
            if (bounds) { map.fitBounds(bounds); }
        }, 100);
    }

    function isActive(name) {
        if ($scope.activeCat === name) { return true; }
        return false;
    }

    $(document).ready(function () {
        $('.openMapButton').on('click', function () {
            resizeMap();
        });
    });

    function range() {
        let min = ($scope.filter.page - 2);
        if (min <= 0) { min = 1; }
        let max = $scope.filter.page + 4;
        if (max >= $scope.totalPages) { max = $scope.totalPages; }
        let step = 1;
        var input = [];
        for (var i = min; i <= max; i += step) { input.push(i); }
        return input;
    };

    function getMarkersFromPaginator(page) {
        $scope.filter.skip = parseInt(page * $scope.filter.limit);
        $scope.filter.page = page;
        loadMarkers();
        $('.realestate-map-list').animate({
            scrollTop: 0
        }, 1000);
        return false;
    };

    function nextMarkersFromPaginator() {
        if (($scope.filter.page + 1) < $scope.totalPages) {
            $scope.filter.page++;
            getMarkersFromPaginator($scope.filter.page);
        }
        return false;
    }

    function prevMarkersFromPaginator() {
        if ($scope.filter.page > 0) {
            $scope.filter.page--;
            getMarkersFromPaginator($scope.filter.page);
        }
        return false;
    }
};
