module.exports = /*@ngInject*/ function ($http) {
	const Provider = {
		loadMarkers : loadMarkers
	};

	function loadMarkers(filter) {
		return $http({
			method : 'POST',
			url    : '/realestate/map',
			data   : {
				filter : filter
			}
		}).then(function (response) {
			return response.data;
		});
	}

	return Provider;
};